/* Container */
.flip-box-container {
  margin-top: -10px;
  display: flex;
  justify-content: left;
  align-items: left;
  perspective: 1000px;
}

/* Flip-box styling */
.flip-box {
  width: 250px;
  border-radius: 10px;
  display: flex;
 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0);
  transform-origin: center;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
}

/* Flipping animation */
.flip-box.flipping {
  transform: rotateX(180deg);
}

/* Content inside flip box */
.step-content {
  color: white;
  text-align: center;
  font-size: 14px;
  display: flex;
  backface-visibility: hidden;
}

.step-number {
  background: #fff;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  position: relative;
  z-index: 1;
}

.step-number::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  box-sizing: border-box;
  animation: rotateBorder 2s linear infinite;
  z-index: -1;
}

@keyframes rotateBorder {
  0% {
    border-color: red;
    transform: rotate(0deg);
  }
  25% {
    border-color: orange;
  }
  50% {
    border-color: yellow;
    transform: rotate(180deg);
  }
  75% {
    border-color: green;
  }
  100% {
    border-color: blue;
    transform: rotate(360deg);
  }
}

/* Step label */
.step-label {
margin-top: 10px;  

}


.dots-loader {
  margin-top: 24px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.dot {
  width: 2px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: dots-bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dots-bounce {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.3;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}
