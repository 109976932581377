body{
    font-family: 'Segoe UI', Arial, sans-serif;
        background-color: #161616;
}

.custom-gx-10 {
    margin-right: 3rem; /* Adjust this value based on your design */
}

.paypal-button-container {
  background-color: #161616;
  }  
  
  .paypal-button-tagline{
    display: none;
  }

.react-tel-input .country-list .country.highlight {
    background-color: rgba(13, 110, 253, 0.25);
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5234fa;
    --bs-btn-border-color: #5234fa;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5234fa;
    --bs-btn-hover-border-color: #5234fa;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #5234fa;
    --bs-btn-active-border-color: #5234fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5234fa;
    --bs-btn-disabled-border-color: #5234fa;
}
.modal-content {
    margin-top: 100px;
}

.form-check-input:checked
{
    background-color: #5234fa;
    border-color: #5234fa;
}
.add-space {
    margin: 4px;
  }

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;


    
}

/* styles.css */
@media (max-width: 768px) {
    .navbar-brand-custom {
      margin-left: 20px;
    }
    .navbar-avatar{
        margin-right:20px ;
    }
    .foostyle{
        margin-left: 20px;
    }
    .content-mob{
        margin-left: 20px;
    }
  }


  .paypal-button paypal-button-number-0 paypal-button-layout-horizontal paypal-button-number-single paypal-button-env-production paypal-button-color-blue paypal-button-text-color-white paypal-logo-color-white  paypal-button-border-radius{
    background-color: #5234fa;
  }

  .custom-paypal-button {
    background-color: #0070ba; /* PayPal blue */
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .custom-paypal-button:hover {
    background-color: #005ea6; /* Darker PayPal blue on hover */
  }
  
  .custom-paypal-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
.paypal-buttons paypal-buttons-context-iframe paypal-buttons-label-pay paypal-buttons-layout-horizontal{
  width: 100px;
}
#zoid-paypal-buttons{
  width: 100px;
}

.loading-throbbers {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

/* General Layout */
.process-flow {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
}

.step-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
 
.step {
  font-size: 16px;
  color: #6c6c6c;
}
 
.step.active {
  color: #ffffff;
  font-weight: 600;
}
 
.divider {
  flex-grow: 1;
  height: 2px;
  background: #333333;
  margin: 0 10px;
}
 



